import React from "react";
import "owl.carousel";
import OwlCarousel from "react-owl-carousel";

// Define an array of testimonial objects
const testimonials = [
  {
    name: " Emily Johnson",
    text: "Their solutions and dedication to my brand were remarkable. I truly appreciate their forward-thinking ideas",
    svgPath: "M100 100 ...",
  },
  {
    name: "David Smith",
    text: "Affinity Digital transformed my business with innovative marketing and automation solutions. highly recommended"    ,
    svgPath: "M150 150 ...",
  },
  {
    name: "Brown",
    text: "Their understanding of AI and its implementation for achieving optimal results is highly noticeable. We continue to work with them and appreciate the great transparency in their work.",
    svgPath: "M150 150 ...",
  },
];

function TestimonialCarousel() {
  const options = {
    autoplay: true,
    nav: true,
    navText: ["←", "→"],
    loop: true,
    margin: 0,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    autoplayTimeout: 5000,
  };

  return (
    <>
      <div className="khalif-testimonial-wrape text-center position-relative testimonial-bg pt-200 pb-100 avatar">
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8"></div>
            <div className="section-title-wrape text-uppercase position-relative mb-80">
              <h3>Testimonials</h3>
              <h4>latest Reviews </h4>
            </div>
            <OwlCarousel
              className="all-testimonial owl-carousel owl-theme text-center"
              {...options}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="item px-lg-5">
                  <h2>{testimonial.text}</h2>
                  <h4 className="mt-3">{testimonial.name}</h4>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <style>
        {`
          /* Styling for the container with display, alignment, padding, and background color */
          .testimonial-container {
            display: flex;
            align-items: center;
            padding: 40px;
            background: #000;
          }
          
          /* Styling for images within the Owl Carousel */
          .owl-theme img {
            width: 100%;
            min-height: 200px;
          }
          
          /* Styling for previous and next navigation buttons */
          .owl-carousel .owl-prev,
          .owl-carousel .owl-next {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            background-color: #fff !important;
            color: #000 !important;
            font-size: 1.3em !important;
            width: 40px;
            height: 40px;
            border-radius: 100% !important;
            font-weight: bold;
            padding: 15px !important;
            text-align: center;
            box-shadow: 0px 0px 10px 0px #000;
          }
          
          /* Styling for previous and next navigation buttons on hover */
          .owl-carousel .owl-prev:hover,
          .owl-carousel .owl-next:hover {
            box-shadow: 0px 0px 0px 0px #000;
            background-color: red !important;
            color: #fff !important;
          }
          
          /* Styling for the previous navigation button */
          .owl-carousel .owl-prev {
            left: -30px;
          }
          
          /* Styling for the next navigation button */
          .owl-carousel .owl-next {
            right: -30px;
          }
          
          /* Styling for the active dot in the pagination */
          .owl-theme .owl-dots .owl-dot.active span {
            background: #ada2ff !important;
            width: 25px;
            transition: 0.2s ease-in-out;
          }
          
          /* Styling for the hover effect on pagination dots */
          .owl-theme .owl-dots .owl-dot:hover span {
            background: #ada2ff !important;
            transition: 0.2s ease-in-out;
          }

          /* @media only screen and (max-width: 1400px) {
            .usa_slide {    
            width: 70% !important;
            padding-top: 8px;
            margin-left: 61px;
            height: 54vh;
          
            }
            } */
          
            @media only screen and (max-width: 1024px) {
              .usa_slide {
                  width: 83% !important;
                  padding-top: 9px;
                  margin-left: 51px;
                  height: 50vh;
              }
              }
            
            @media only screen and (max-width: 768px) {
            .usa_slide {
              width: 65% !important;
              padding-top: 11px;
              margin-left: 61px;
              height: 59vh;
            }
            }
            
            @media only screen and (max-width: 425px) {
              .usa_slide {
                width: 83% !important;
                padding-top: 8px;
                margin-left: 45px;
                height: 43vh;
              }
              .hero-content h1 span {
                font-size: 29px;
                font-weight: 700;
                text-transform: uppercase;
                color: white;
                -webkit-text-stroke: 1px #fff;
            }
          
            h2.style-title {
              position: absolute;
              left: 24%;
              top: 15%;
              bottom: 0;
              right: 0;
              text-transform: uppercase;
              color: #ffffff24 !important;
              -webkit-text-stroke: 1px #585858;
              font-size: 104px;
              z-index: -1;
          }
          
          .usabtn {
            position: absolute;
            padding: 0px;
          }
          
          .btn-2 {
            width: 215px;
            height: 100px;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            font-family: 'Antonio', sans-serif;
            border-radius: 5%;
            padding: 50px 0;
            position: relative;
            display: inline-block;
            transition: all .3s;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .btn-2:after {
            position: absolute;
            content: '';
            width: 240px; 
            height: 130px;
            left: -10px;
            top: -10px;
            right: -10px;
            bottom: -10px;
            border-radius: 5%;
            border: 2px solid #595959;
            transition: all .3s;
            z-index: 1;
          }
          
          .about-short-brief p {
            margin-bottom: 50px;
            margin-left: 0;
          }
          
          .about-short-brief p {
            margin-bottom: 50px;
            margin-left: 0;
          }

          .owl-carousel .owl-prev {
            left: 0px;
        }

        .owl-carousel .owl-next {
          right: 0px;
      }
            }
            
            @media only screen and (max-width: 375px) {
              .usa_slide {
                width: 84% !important;
                padding-top: 9px;
                margin-left: 39px;
                height: 37vh;
              }
          
              .hero-content h1 span {
                font-size: 25px;
                font-weight: 700;
                text-transform: uppercase;
                color: white;
                -webkit-text-stroke: 1px #fff;
            }
          
            h2.style-title {
              position: absolute;
              left: 24%;
              top: 15%;
              bottom: 0;
              right: 0;
              text-transform: uppercase;
              color: #ffffff24 !important;
              -webkit-text-stroke: 1px #585858;
              font-size: 100px;
              z-index: -1;
          }
          
          .usabtn {
            position: absolute;
            padding: 0px;
          }
            }
            
            @media only screen and (max-width: 320px) {
            .usa_slide {
              width: 82% !important;
              padding-top: 8px;
              margin-left: 37px;
              height: 31vh;
            }
          
            .hero-content h1 span {
              font-size: 21px;
              font-weight: 700;
              text-transform: uppercase;
              color: white;
              -webkit-text-stroke: 1px #fff;
          }
          
          h2.style-title {
            position: absolute;
            left: 24%;
            top: 15%;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            color: #ffffff24 !important;
            -webkit-text-stroke: 1px #585858;
            font-size: 99px;
            z-index: -1;
          }
          
          .usabtn {
            position: absolute;
            padding: 0px;
          }
            }
          
            `}
      </style>
    </>
  );
}

export default TestimonialCarousel;
