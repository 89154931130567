import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleContactus = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', email);

    try {
      const response = await axios.post('https://affinityapi.dev-sh.xyz/api/message', formData);

      if (response.data.status === true) {
        setMessage(response?.data?.message);
        toast.success("Details submitted successfully");
      } else {
        toast.error('Submission failed');
      }
      setEmail('');

    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <div className="khalif-footer-wrape pt-120 bgc-2">
        <div className="khalif-footer-style-shape">
          <div className="footer-shape-1" data-parallax='{"y": -50}'></div>
          <div className="footer-shape-2" data-parallax='{"x": -70}'></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="khalif-footer-single-widget mb-50">
                <div className="khalif-subscribe-content">
                  <h2>get in <span>touch</span></h2>
                  <form className="khalif-subscribe-form position-relative" onSubmit={handleContactus}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Type Your Email here"
                      value={email}
                      onChange={handleEmailChange}
                      className='bg-white'
                    />
                    <button type="submit" className="btn-4">
                      <span>
                        <svg
                          id="Layer_8"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 128 95.91"
                        >
                          <path
                            d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                            transform="translate(-131.88 -418.11)"
                          ></path>
                        </svg>
                      </span>
                    </button>
                    <p className="form-message float-left mt-15">{message}</p>
                  </form>
                </div>
              </div>
            </div>
            <div className="offset-xl-2 col-xl-3 col-lg-3 col-md-3">
              <div className="khalif-footer-single-widget mb-50">
                <div className="widget_nav_menu">
                  <ul className="menu">
                    <li><Link to="/servicesIndex">ARTIFICIAL INTELLIGENCE</Link></li>
                    <li><Link to="/serviceMarketingIndex">BUSINESS DEVELOPMENT</Link></li>
                    <li><Link to="/serviceinfluenceIndex">DIGITAL MARKETING</Link></li>
                    <li><Link to="/blogsIndex">BLOG</Link></li>
                    <li><Link to="/contactIndex">CONTACT US</Link></li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="offset-xl-1 col-xl-2 offset-lg-1 col-lg-2 col-md-3">
              <div className="khalif-footer-single-widget pb-50">
                <div className="widget-footer-contact-info">
                  <ul className="footer-contact-info custom-html-widget">
                    <li><span>email</span><Link to="mailto:teams@affinitydigital.co">teams@affinitydigital.co</Link></li>
                    <li><span>phone</span>+1 (647) 660-6700 <br /> +1 (647) 660-1619</li>

                    <li><span>address</span>5th Floor <br /> 212 King St W, Toronto</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="khalif-copyright-wrape">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="khalif-copyright-content pt-40 pb-30">
                  {/* <div className="footer-copyright">
                  <p>&copy; All Right Reserved By <Link to="/"><strong style={{color: '#ada2ff'}}> Affinity </strong></Link></p>
                </div> */}
                  <ul className="footer-social float-right pt-2">
                    <li><a href="https://www.facebook.com/AffinityDigitalAI"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com/affinity_ai"><i className="fa fa-twitter"></i></a></li>
                    <li><a href="https://Instagram.com/affinitydigital_collective"><i className="fa fa-instagram"></i></a></li>
                    <li><a href="https://linkedin.com/company/affinity-digital-marketing-agency"><i className="fa fa-linkedin"></i></a></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      <chat-widget
        location-id="ZdzlOLLdpMbdzkBGc5Aa"
        style={{
          '--chat-widget-primary-color': '#9192FF',
          '--chat-widget-active-color': '#9192FF',
          '--chat-widget-bubble-color': '#9192FF'
        }}
        heading="Arya K."
        sub-heading="Hey there! I am Arya available to answer your questions, feel free to ask any questions regarding our services."
        prompt-msg="Hi Arya here, I am live to answer your questions"
        use-email-field="true"
        revisit-prompt-msg="Welcome back {{name}}, Arya is still here to assist you, have any questions?"
        support-contact="kreza@affinitydigital.co"
        prompt-avatar="https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2FZdzlOLLdpMbdzkBGc5Aa%2Fchat-widget-person?alt=media&token=d5cd35a1-0d70-499b-84ca-2ea2c61d639c"
        agency-name="CRM - Affinity Digital"
        locale="en-us"
        live-chat-intro-msg="Sure, I can assist you on this"
        chat-type="liveChat"
        live-chat-user-inactive-msg="I am really sorry for the delay as we are facing a huge traffic on agents right now, please leave your contact details and we will get back to you soon."
        live-chat-feedback-note="Thank you, for taking your time."
        primary-color="#9192FF">
      </chat-widget>

    </>
  );
};

export default Footer;
