import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./contactForm";

function ContactArea() {
  return (
    <>
      <div
        id="khalif-contact-area"
        className="khalif-contact-area mt-110 mb-110 avatar"
      >
        <div id="contact-info-area" className="contact-info-area">
          <div className="container">
            <div className="row text-center">
              <div className="section-title-wrape text-uppercase position-relative mb-80">
                <h3>contact us</h3>
                <h4>contact us</h4>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInLeft">
                <div className="contact-info-box usa_hight">
                  <div className="contact-style-img">
                    <img src="assets/img/contact/1.png" alt="Affinity" />
                  </div>
                  <div className="contact-info-icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 473.806 473.806"
                      style={{ enableBackground: "new 0 0 473.806 473.806" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
							c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
							c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
							c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
							c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
							c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
							c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
							C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
							c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
							c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
							c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
							c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
							c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
							c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
							l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
							C420.456,377.706,420.456,388.206,410.256,398.806z"
                          />
                          <path
                            d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
							c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
							S248.656,111.506,256.056,112.706z"
                          />
                          <path
                            d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
							c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
							c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </div>
                  <ul className="contact-info usa_le">
                    <li>+1 (647) 660-6700</li>
                    <li>+1 (647) 660-1619</li>
                    {/* <li>+1 (234) 567-8991</li> */}
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInLeft mt-1"
                data-aos="fade-up"
              >
                <div className="contact-info-box usa_hight">
                  <div className="contact-style-img">
                    <img src="assets/img/contact/1.png" alt="Business Development" />
                  </div>
                  <div className="contact-info-icon">
                    <svg
                      version="1.1"
                      id="Capa_11"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 511.999 511.999"
                      style={{ enableBackground: "new 0 0 511.999 511.999" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256.98,40.005c-5.53-0.04-10.04,4.41-10.08,9.93c-0.04,5.52,4.4,10.03,9.93,10.07c5.52,0.04,10.03-4.4,10.07-9.92
			C266.94,44.565,262.5,40.055,256.98,40.005z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M256.525,100.004c-38.611-0.274-70.232,30.875-70.524,69.474c-0.291,38.597,30.875,70.233,69.474,70.524
			c0.179,0.001,0.357,0.002,0.536,0.002c38.353,0,69.698-31.056,69.988-69.476C326.29,131.933,295.125,100.295,256.525,100.004z
			 M256.009,220.005c-0.126,0-0.258-0.001-0.384-0.002c-27.571-0.208-49.833-22.806-49.625-50.375
			c0.207-27.445,22.595-49.627,49.991-49.627c0.126,0,0.258,0.001,0.384,0.002c27.571,0.208,49.833,22.806,49.625,50.375
			C305.792,197.823,283.405,220.005,256.009,220.005z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M299.631,47.589c-5.202-1.846-10.921,0.879-12.767,6.085c-1.845,5.206,0.88,10.921,6.085,12.767
			c44.047,15.611,73.402,57.562,73.05,104.389c-0.041,5.522,4.402,10.033,9.925,10.075c0.025,0,0.051,0,0.077,0
			c5.486,0,9.956-4.428,9.998-9.925C386.415,115.633,351.706,66.046,299.631,47.589z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M317.357,376.442c66.513-85.615,108.08-130.26,108.641-205.164C426.702,77.035,350.22,0,255.984,0
			C162.848,0,86.71,75.428,86.002,168.728c-0.572,76.935,41.767,121.519,108.739,207.7C128.116,386.384,86.002,411.401,86.002,442
			c0,20.497,18.946,38.89,53.349,51.79c31.313,11.742,72.74,18.209,116.649,18.209s85.336-6.467,116.649-18.209
			c34.403-12.901,53.349-31.294,53.349-51.791C425.998,411.417,383.923,386.406,317.357,376.442z M106.001,168.879
			C106.625,86.55,173.8,20,255.986,20c83.159,0,150.633,67.988,150.013,151.129c-0.532,71.134-44.614,114.971-114.991,206.714
			c-12.553,16.356-24.081,31.82-34.993,46.947c-10.88-15.136-22.178-30.323-34.919-46.953
			C147.81,282.253,105.459,241.729,106.001,168.879z M256,492c-85.851,0-149.999-26.397-149.999-50
			c0-17.504,38.348-39.616,102.826-47.273c14.253,18.701,26.749,35.691,39.005,53.043c1.872,2.65,4.914,4.228,8.159,4.23
			c0.003,0,0.006,0,0.009,0c3.242,0,6.283-1.572,8.158-4.217c12.14-17.126,24.978-34.535,39.109-53.045
			c64.418,7.665,102.732,29.77,102.732,47.263C405.998,465.603,341.851,492,256,492z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </div>
                  <ul className="contact-info usa_lets">
                    <li>5th Floor <br />212 King St W, Toronto</li>
                    {/* <li>New York, NY-12548</li> */}
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInLeft">
                <div className="contact-info-box usa_hight">
                  <div className="contact-style-img">
                    <img src="assets/img/contact/1.png" alt="Social Media" />
                  </div>
                  <div className="contact-info-icon">
                    <svg
                      id="Layer_17"
                      enable-background="new 0 0 2000 2000"
                      height="512"
                      viewBox="0 0 2000 2000"
                      width="512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m1971.796 794.255c.206-2.012.234-4.038.111-6.062.707-11.664-4.048-23.479-13.865-31.335l-232.37-185.973v-91.792c0-86.281-70.193-156.474-156.474-156.474h-153.726l-385.645-308.64c-.013-.017-.023-.035-.036-.053-7.51-9.383-18.637-14.13-29.792-13.917-11.154-.213-22.281 4.533-29.79 13.917-.014.017-.024.035-.037.053l-385.641 308.64h-153.729c-86.281 0-156.474 70.193-156.474 156.474v91.793l-232.37 185.971c-9.818 7.856-14.572 19.671-13.865 31.335-.124 2.024-.095 4.05.11 6.062-.132 1.279-.205 2.577-.205 3.891v1025.649c0 97.16 83.422 176.204 185.96 176.204h1572.083c102.538 0 185.96-79.045 185.96-176.204v-1025.648c.001-1.314-.071-2.612-.205-3.891zm-246.123-128.04 152.408 121.977-152.408 121.978zm-725.673-580.78 296.357 237.182h-592.712zm-651.243 393.656c0-45.238 36.807-82.045 82.045-82.045h1138.396c45.238 0 82.045 36.807 82.045 82.045v110.138c0 .035-.001.071 0 .105v380.404l-651.243 521.213-651.243-521.212zm-74.429 187.124v243.955l-152.409-121.978zm-171.9 1157.581v-955.872l605.701 484.764-594.932 514.698c-6.897-13.221-10.769-27.999-10.769-43.59zm1683.614 101.775h-1572.083c-15.802 0-30.836-3.032-44.469-8.464l597.735-517.123 202.948 162.427c.013.016.023.034.037.051 7.349 9.182 18.163 13.959 29.078 13.959.237 0 .474-.024.712-.028.238.004.476.028.714.028 10.915 0 21.729-4.777 29.078-13.959.013-.016.023-.034.036-.051l202.948-162.427 597.734 517.123c-13.633 5.431-28.667 8.464-44.468 8.464zm111.53-101.775c0 15.591-3.874 30.369-10.77 43.591l-594.932-514.698 605.702-484.764zm-1280.571-1074.623h769.927c20.553 0 37.215-16.661 37.215-37.215s-16.661-37.215-37.215-37.215h-769.927c-20.554 0-37.215 16.661-37.215 37.215 0 20.553 16.661 37.215 37.215 37.215zm807.142 186.691c0-20.554-16.661-37.215-37.215-37.215h-769.927c-20.554 0-37.215 16.661-37.215 37.215s16.661 37.215 37.215 37.215h769.927c20.554 0 37.215-16.661 37.215-37.215z" />
                    </svg>
                  </div>
                  <ul className="contact-info usa_let  pb-4">
                    <li>
                      <Link to="teams@affinitydigital.co">
                      teams@affinitydigital.co
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="mailto:sales@website.com">
                        sales@website.com
                      </Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
      <style>
        {`
        .usa_hight{
          height: 310px;
        }
        .btn-usa{
          font-size: 33px;
        }
        .usa_le {
          text-align: left;
          padding-left: 125px;
      }      
        .usa_let{
          text-align: left;
          padding-left: 100px;
        }
        .usa_lets {
          text-align: center;
          padding-left: 3px;
      }
        
        `}
      </style>
    </>
  );
}

export default ContactArea;
