import React, { useEffect } from "react";
import InfluenceHero from "./influenceHero";
import InfluenceServices from "./influenceservices";
// import VideoComponent from "../../../components/video";
// import InfluencePricing from "./influencePricing";
// import InfluenceAccordion from "./influenceAccordian";
import BrandsComponent from "../../../components/brands";
function ServicesInfluenceIndex() {
  useEffect(()=>{
    document.title = "AFFINITY Digital Marketing"
  },[])
  return (
    <>
    <InfluenceHero />
    <InfluenceServices />
    {/* <VideoComponent /> */}
    {/* <InfluencePricing /> */}
    {/* <InfluenceAccordion /> */}
    <BrandsComponent />
    </>
  );
}

export default ServicesInfluenceIndex;
