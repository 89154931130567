import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isClosing] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  // Function to set the active menu for services and casestudies
  const handleSubMenuClick = (mainMenuItem) => {
    setActiveMenuItem(mainMenuItem);
  };

  const toggleMenu = () => {
    if (isMenuOpen) {
      setMenuOpen(false);
      document.body.style.overflow = "";
    } else {
      setMenuOpen(true);
    }

    const divs = document.getElementsByClassName("offcanvas-backdrop");
    for (let i = 0; i < divs.length; i++) {
      divs[i].remove();
      break;
    }
  };
  document.body.style.paddingRight = "0";
  console.log(isMenuOpen, "isMenuOpen");

  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${isMenuOpen ? "show" : "hide"} ${
          isClosing ? "closing" : ""
        }`}
        tabIndex="-1"
        id="offcanvasmanu" 
      >
        <div className="offcanvas-header">
          <div className="khalif-canvas-logo">
            <Link to="/">
              <img
                src="assets/img/Affinityb.png"
                alt="AffinityDigital"
                style={{ maxWidth: "150px" }}
              />
            </Link>
          </div>
          <button
            type="button"
            onClick={toggleMenu}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body khalif-offcanvas-mobile-menu-area">
          <nav>
            <ul className="pl-0">
              <li className={activeMenuItem === "home" ? "active" : ""}>
                <Link to="/" onClick={() => (handleMenuItemClick("home")+  setMenuOpen(false))}>
                  home
                </Link>
              </li>
              <li className={activeMenuItem === "services" ? "active" : ""}>
                <Link to="#" onClick={() => handleMenuItemClick("")}>
                  services <i className="fa fa-angle-down"></i>
                </Link>
                <ul className="sub-menu text-left pl-0">
                  <li>
                    <Link onClick={() =>  setMenuOpen(false)} to="/servicesIndex">Artificial Intelligence</Link>
                  </li>
                  <li>
                    <Link onClick={() =>  setMenuOpen(false)} to="/serviceMarketingIndex">Business Development</Link>
                  </li>
                  <li>
                    <Link onClick={() =>  setMenuOpen(false)} to="/serviceinfluenceIndex">
                    Digital Marketing
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={activeMenuItem === "casestudies" ? "active" : ""}>
                <Link to="#" onClick={() => handleMenuItemClick("casestudies")}>
                  case studies <i className="fa fa-angle-down"></i>
                </Link>
                <ul className="sub-menu text-left pl-0">
                  <li>
                    <Link onClick={() =>  setMenuOpen(false)} to="/developmentIndex">development</Link>
                  </li>
                  <li>
                    <Link onClick={() =>  setMenuOpen(false)} to="/marketingIndex">marketing</Link>
                  </li>
                </ul>
              </li>
              <li className={activeMenuItem === "blog" ? "active" : ""}>
                <Link
                  to="/blogsIndex"
                  onClick={() => (handleMenuItemClick("blog")+  setMenuOpen(false))}
                >
                  blog
                </Link>
              </li>
              <li className={activeMenuItem === "contact" ? "active" : ""}>
                <Link
                  to="/contactIndex"
                  onClick={() => (handleMenuItemClick("contact")+  setMenuOpen(false))}
                >
                  contact
                </Link>
              </li>
              <li className={activeMenuItem === "Packages" ? "active" : ""}>
                <a
                  href="https://funnel.affinitydigital.co/"
                >
                  all packages
                </a>
              </li>
            </ul>
          </nav>
          <div className="offcanvas-social">
            <ul className="text-center">

              <li>
                <a href="https://www.facebook.com/AffinityDigitalAI">
                  <i className="fa fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/affinity_ai">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/affinitydigital_collective/">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
              <a href="https://www.linkedin.com/company/affinity-digital-marketing-agency">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
             
            </ul>
          </div>
        </div>
      </div>

      <header>
        <div className="khalif-header-wrape">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="khalif-main-manu-content">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src="assets/img/Affinityw.png"
                        alt="Affinity Digital"
                        className="img custm-logo"
                      />
                    </Link>
                  </div>
                  <Link
                    className="header-top-modal-btn float-right"
                    data-bs-dismiss="offcanvas"
                    to="#offcanvasmanu"
                    role="button"
                    aria-controls="offcanvasmanu"
                  >
                    <div className="btn-1" onClick={() => setMenuOpen(true)}>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                  <ul className="header-cta-btn float-right">
                    <li>
                      <a
                      href="https://funnel.affinitydigital.co/"
                      className="magic-hover magic-hover__square"
                      > All Packages
                      </a>
                    </li>
                  </ul>
                  <div className="main-menu-area text-center">
                    <div className="main-menu">
                      <nav>
                        <ul>
                          <li
                            className={
                              activeMenuItem === "home" ? "active" : ""
                            }
                          >
                            <Link
                              to="/"
                              className="cstm"
                              onClick={() => handleMenuItemClick("home")}
                            >
                              home
                            </Link>
                          </li>
                          <li
                            className={
                              activeMenuItem === "services" ? "active" : ""
                            }
                            onClick={() => handleSubMenuClick("services")}
                          >
                            <Link
                              to="#"
                              onClick={() => handleMenuItemClick("services")}
                            >
                              services <i className="fa fa-angle-down"></i>
                            </Link>
                            <ul className="sub-menu text-left text-capitalize ">
                              <li
                                className={
                                  activeMenuItem === "services" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/servicesIndex"
                                  onClick={() => handleSubMenuClick("services")}
                                >
                                  Artificial Intelligence
                                </Link>
                              </li>
                              <li
                                className={
                                  activeMenuItem === "services" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/serviceMarketingIndex"
                                  onClick={() => handleSubMenuClick("services")}
                                >
                                   Business Development
                                </Link>
                              </li>
                              <li
                                className={
                                  activeMenuItem === "services" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/serviceinfluenceIndex"
                                  onClick={() => handleSubMenuClick("services")}
                                >
                                Digital Marketing
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              activeMenuItem === "casestudies" ? "active" : ""
                            }
                            onClick={() => handleSubMenuClick("casestudies")}
                          >
                            <Link
                              to="#"
                              onClick={() => handleMenuItemClick("casestudies")}
                            >
                              case studies <i className="fa fa-angle-down"></i>
                            </Link>
                            <ul className="sub-menu text-left">
                              <li>
                                <Link to="/developmentIndex">development</Link>
                              </li>
                              <li>
                                <Link to="/marketingIndex">marketing</Link>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              activeMenuItem === "blog" ? "active" : ""
                            }
                          >
                            <Link
                              to="/blogsIndex"
                              onClick={() => handleMenuItemClick("blog")}
                            >
                              blog
                            </Link>
                          </li>
                          <li
                            className={
                              activeMenuItem === "contact" ? "active" : ""
                            }
                          >
                            <Link
                              to="/contactIndex"
                              className="cstm2"
                              onClick={() => handleMenuItemClick("contact")}
                            >
                              contact
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <style>
        {`
        .custm-logo{
          height: 67px !important; 
          width: 135px !important;
        }
        ul.header-cta-btn li {
          border: 1px solid white;
          margin: 0;
          border-radius: 10px;
          background: #ada2ff;
      }
      
        a.cstm {
          margin-left: 25px;
        }
        a.cstm2 {
          position: relative;
          left: -15px;
        }    
        `}
      </style>
    </>
  );
}
export default Header;
