import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const MobileDevelopment = () => {

  const { id } = useParams();

  const [data, setData] = useState([]);
  console.log(data, "data3");


  useEffect(() => {
    const fetchDevelopmentData = async () => {
      try {
        const userResponse = await axios.get(`https://affinityapi.dev-sh.xyz/api/quantilitics/${id}`, {
          headers: {
            Accept: "application/json",
          },
        });

        console.log(userResponse, "Success");

        if (userResponse.data.status === true) {
          toast.success("Please Check your details");
          setData(userResponse?.data?.quantilytic);
        console.log(userResponse?.data?.quantilytic, "Success");
        // Set empty array if data is undefined
        } else {
          toast.error("Data not found");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };


    window.scrollTo(0, 0);
    fetchDevelopmentData();
  }, [id]);

  // const carouselRef = useRef(null);

  useEffect(() => {
    const initializeCarousel = () => {
      // Ensure that the element exists before accessing it
      const carouselElement = document.getElementById('carouselExampleIndicators');
      if (carouselElement) {
        // Initialize the Bootstrap Carousel
        new window.bootstrap.Carousel(carouselElement);
      } else {
        console.error('Carousel element not found.');
      }
    };

    // Run the initialization function after the DOM is fully loaded
    document.addEventListener('DOMContentLoaded', initializeCarousel);

    // Clean up event listener to prevent memory leaks
    return () => {
      document.removeEventListener('DOMContentLoaded', initializeCarousel);
    };
  }, []);
  

  return (
    <>

      <div
        id="khalif-breadcrumb-wrap"
        className="khalif-breadcrumb-wrap hero-bg breadcrumb-bg text-center d-flex align-items-center avatar"
        style={{ backgroundImage: `url('/assets/img/Portfolio.png')` }}
      >
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8">
              <div className="khalif-breadcrumb-content">
                <h2><strong>{data?.title}</strong></h2>
                <p className="text-white">{data?.intro}</p>
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/" title="Home">
                      Home 
                    </Link>
                  </li>
                  <li className="breadcrumb-item active text-white">Case Studies</li>
                </ul>
                {/* <p>
              In a recent e-commerce platform overhaul, we boosted performance
              and user experience through modern technology and agile
              development.
            </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container dark-bg-1 flex-min-height-box mt-100 mb-100">
        <div className=" row ">
          <div className="row project-content top-padding-60 bottom-padding-60" >
            <div className="col-lg-6 text-black">
              <div className="content-right-margin-20 ">
                <h2 className="text-color-4 fs-1 title-style text-black">
                  <strong>DESCRIPTION:</strong>
                </h2>
                <p
                  className="p-style-medium fs-3 text-color-3 text-black"
                  style={{ letterSpacing: 1 }}
                >
                  {data?.description}
                </p>
                <h3 className="text-black">Technology Stack App:</h3>
                <p className="text-black">{data?.technology_stack_app}</p>
                <h3 className="text-black">Technology Stack APIs:</h3>
                <p className="text-black">{data?.technology_stack_appis}</p>

                <h3 className="text-black">Admin Panel & Website:</h3>
                <p className="text-black">{data?.admin_panel}</p>
                <h3 className="text-black">Database:</h3>
                <p className="text-black">{data?.database}</p>
                <h3 className="text-black">Category:</h3>
                <p className="text-black">{data?.category}</p>
                <h3 className="text-black">Third Party:</h3>
                <p className="text-black">{data?.third_party}</p>
              </div>
            </div>
            <div className=" col-lg-6 avatar">
              <div className="content-left-margin-20 ">
                <div className="box1 ">
                  <img className="position-absolute" alt="Iphone" src="/assets/img/iphone-xx.webp" />
                  <div className="slider">

                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                      {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div> */}
                      <div className="carousel-inner position-relative"  >
                        {console.log(data?.all_images,'all_images==')}
                      {/* {data && data.length > 0 ? ( */}
                     { data?.all_images?.map((item, index) => (
                        <div key={index}>
                            {console.log(item,'item====>')}
                            <div className={`carousel-item ${index === 0 ? 'active mr-0' : ' '}`} data-bs-interval="5000" >
                              <img src={item} alt={`Slide ${index}`} className="d-block w-75 usa_slide" />
                            </div>
                          </div>
                        ))}
                        {/* //   ) : (
                        //   <p className="d-block w-75 usa_slide text-black">No data available</p>
                        // ) 
                        // }  */}
                      </div>

                      <button className="carousel-control-prev d-none" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next d-none" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <style>
        {
          `
.khalif-breadcrumb-content h2 {
    color: #fff;
    font-size: 63px ;
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.carousel-control-prev {
  left: 22px;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #9192ff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

  .carousel-inner {
    position: relative;
    width: 100%;
    margin-left: 19px;
    overflow: hidden;
}

.box1 {
  float: right;
}

.carousel {
  position: relative;
  width: 93%;
  left: 9px;
}

.position-absolute {
  position: absolute !important;
  width: 57%;
}
.position-absolute img{
margin-bottom: 98px;
}

.usa_slide {
  width: 100% !important;
  padding-top: 54px;
  margin-left: 0px;
  height: 75vh;
}
.content-left-margin-20 {
float: inline-start;
  margin-left: 92px;
}
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%; 
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}
  `
        }
      </style>

    </>
  );
};

export default MobileDevelopment;
