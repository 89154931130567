import React, { useEffect } from "react";
import ServicesHero from "./servicesHero";
import Services from "./services";
// import VideoComponent from "../../../components/video";
// import ServicesPricing from "./servicesPricing";
// import ServicesAccordion from "./servicesAccordian";
import BrandsComponent from "../../../components/brands";
function ServicesIndex() {
useEffect(()=>{
  document.title = "AFFINITY AI-Integration"
},[])
  return (
    <>
      <ServicesHero />
      <Services />
      {/* <VideoComponent /> */}
      {/* <ServicesPricing /> */}
      {/* <ServicesAccordion /> */}
      <BrandsComponent />
    </>
  );
}

export default ServicesIndex;
