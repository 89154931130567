// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './layout/header';
import VideoComponent from './components/video';
import BrandsComponent from './components/brands';
import Index from './pages';
import HeroSec from './pages/index/heroSec';
import ShortAbout from './pages/index/shortAbout';
import ServicesSection from './pages/index/services';
import VideoSection from './pages/index/video';
import PortfolioSection from './pages/index/portfolio';
import TestimonialSection from './pages/index/testimonial';
import BlogCarousel from './pages/index/blog';
import BrandCarousel from './pages/index/brand';
import Footer from './layout/footer';
import AboutIndex from './pages/about/aboutIndex';
import AboutHero from './pages/about/aboutHero';
import AboutArea from './pages/about/aboutArea';
import AboutCounter from './pages/about/aboutCounter';
import AboutTeam from './pages/about/aboutTeam';
import Services from './pages/services/development/services';
import ServicesIndex from './pages/services/development/servicesIndex';
import ServicesHero from './pages/services/development/servicesHero';
import ServicesPricing from './pages/services/development/servicesPricing';
import ServicesAccordion from './pages/services/development/servicesAccordian';
import MarketingAccordion from './pages/services/marketing/marketingAccordian';
import ServicesMarketingIndex from './pages/services/marketing/serviceMarketingIndex';
import MarketingHero from './pages/services/marketing/marketingHero';
import MarketingServices from './pages/services/marketing/marketingservices';
import MarketingPricing from './pages/services/marketing/marketingPricing';
import InfluenceAccordion from './pages/services/influence/influenceAccordian';
import ServicesInfluenceIndex from './pages/services/influence/serviceinfluenceIndex';
import InfluenceHero from './pages/services/influence/influenceHero';
import InfluenceServices from './pages/services/influence/influenceservices';
import InfluencePricing from './pages/services/influence/influencePricing';
import DevelopmentIndex from './pages/caseStudy/development/developmentIndex';
import DevelopmentAccordion from './pages/caseStudy/development/developmentAccordian';
import DevelopmentHero from './pages/caseStudy/development/developmentHero';
import DevelopmentServices from './pages/caseStudy/development/developmentServices';
import MarketingIndex from './pages/caseStudy/marketing/marketingIndex';
import MarketingAcordion from './pages/caseStudy/marketing/marketingAcordian';
import MarketingHeroo from './pages/caseStudy/marketing/marketingHeroo';
import MarketingService from './pages/caseStudy/marketing/marketingService';
import BlogsIndex from './pages/blogs/blogsIndex';
import BlogsHero from './pages/blogs/blogsHero';
import BlogsArea from './pages/blogs/blogsArea';
import SmoothScroll from './ScrollTop';
import { useRef } from 'react';
import ContactIndex from './pages/contact/contactIndex';
import ContactHero from './pages/contact/contactHero';
import ContactArea from './pages/contact/contactArea';
import ContactForm from './pages/contact/contactForm';
import Pricing from './pages/index/pricing';
import NewDevelopment from './pages/caseStudy/development/newDevelopment';
import MobileDevelopment from './pages/caseStudy/development/MobileDovelopment';

function App() {
  const top = useRef();
  return (
    <>
    <div ref={top} />
    <BrowserRouter>
    <SmoothScroll top={top} />
    <Header />
    <Routes>
      {/* components routes start  */}
      <Route path='/video' element={<VideoComponent />}/>
      <Route path='/brands' element={<BrandsComponent />}/>
      {/* components routes end  */}
      <Route path='/' element={<Index />}/>
      <Route path='/heroSec' element={<HeroSec />}/>
      <Route path='/pricing' element={<Pricing />}/>
      <Route path='/shortAbout' element={<ShortAbout />}/>
      <Route path='/services' element={<ServicesSection />}/>
      <Route path='/video' element={<VideoSection />}/>
      <Route path='/portfolio' element={<PortfolioSection />}/>
      <Route path='/testimonial' element={<TestimonialSection />}/>
      <Route path='/blog' element={<BlogCarousel />}/>
      <Route path='/brand' element={<BrandCarousel />}/>
      <Route path='/aboutIndex' element={<AboutIndex />}/>
      <Route path='/aboutHero' element={<AboutHero />}/>
      <Route path='/aboutArea' element={<AboutArea />}/>
      <Route path='/aboutCounter' element={<AboutCounter />}/>
      <Route path='/aboutTeam' element={<AboutTeam />}/>
      <Route path='/services' element={<Services />}/>
      <Route path='/servicesIndex' element={<ServicesIndex />}/>
      <Route path='/servicesHero' element={<ServicesHero />}/>
      <Route path='/servicesPricing' element={<ServicesPricing />}/>
      <Route path='/servicesAccordian' element={<ServicesAccordion />}/>
      <Route path='/marketingAccordian' element={<MarketingAccordion />}/>
      <Route path='/serviceMarketingIndex' element={<ServicesMarketingIndex />}/>
      <Route path='/marketingHero' element={<MarketingHero />}/>
      <Route path='/marketingservices' element={<MarketingServices />}/>
      <Route path='/marketingPricing' element={<MarketingPricing />}/>
      <Route path='/influenceAccordian' element={<InfluenceAccordion />}/>
      <Route path='/serviceinfluenceIndex' element={<ServicesInfluenceIndex />}/>
      <Route path='/influenceHero' element={<InfluenceHero />}/>
      <Route path='/influenceservices' element={<InfluenceServices />}/>
      <Route path='/influencePricing' element={<InfluencePricing />}/>
      <Route path='/developmentIndex' element={<DevelopmentIndex/>}/>
      <Route path='/developmentAccordian' element={<DevelopmentAccordion />}/>
      <Route path='/developmentHero' element={<DevelopmentHero />}/>
      <Route path='/developmentServices' element={<DevelopmentServices />}/>
      <Route path='/marketingIndex' element={<MarketingIndex/>}/>
      <Route path='/marketingAcordian' element={<MarketingAcordion />}/>
      <Route path='/marketingHeroo' element={<MarketingHeroo />}/>
      <Route path='/marketingService' element={<MarketingService />}/>
      <Route path='/blogsIndex' element={<BlogsIndex />}/>
      <Route path='/blogsHero' element={<BlogsHero />}/>
      <Route path='/blogsArea' element={<BlogsArea />}/>
      <Route path='/contactIndex' element={<ContactIndex />}/>
      <Route path='/contactHero' element={<ContactHero />}/>
      <Route path='/contactArea' element={<ContactArea />}/>
      <Route path='/contactForm' element={<ContactForm />}/>
      <Route path='/web_development/:id' element={<NewDevelopment />}/>
      <Route path='/mobile_development/:id' element={<MobileDevelopment />}/>


    </Routes>
    <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;
